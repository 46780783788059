const STORAGE_KEY = "pendingFreightQuote";

export const setPendingData = (pendingFormData, serviceInfo) => {
  const data = { pendingFormData, service: serviceInfo };
  localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
  console.log("Stored pending data:", data);
};

export const getPendingData = () => {
  const storedData = localStorage.getItem(STORAGE_KEY);
  return storedData ? JSON.parse(storedData) : null;
};

export const clearPendingData = () => {
  localStorage.removeItem(STORAGE_KEY);
  console.log("Pending data cleared");
};
