import React, { useState, useEffect } from "react";
import QuotesTable from "../Components/QuotesTable";
import userService from "../../../services/userService";
import { useLocation } from "react-router-dom";

export default function Quotes() {
  const [filterShipmentData, setFilterShipmentData] = useState([]);
  const [quotationData, setQuotationData] = useState([]);
  const location = useLocation();
  const [description, setDescription] = useState(
    "This page provides a comprehensive view of all quotations, including those that are unpaid, paid, pending and completed."
  );

  useEffect(() => {
    const getQuotationData = async () => {
      const response = await userService.getUserQuotation();
      setQuotationData(response);
    };

    getQuotationData();
  }, []);

  useEffect(() => {
    const applyFilter = () => {
      const path = location.pathname.replace("/dashboard/", "");
      switch (path) {
        case "all-quotations":
          setFilterShipmentData(quotationData);
          setDescription(
            "This page provides a comprehensive view of all quotations, including those that are unpaid, paid, pending and completed."
          );
          break;

        case "paid-quotations":
          const paidData = quotationData.filter(
            (item) => item.payment_status === "paid"
          );

          setFilterShipmentData(paidData);
          setDescription(
            "This page displays a table listing all paid quotations. You can easily track the progress of your paid quotation and manage them efficiently."
          );
          break;
        case "unpaid-quotations":
          const unPaidData = quotationData.filter(
            (item) => item.payment_status === "unpaid"
          );

          setFilterShipmentData(unPaidData);
          setDescription(
            "This page displays a table listing all unpaid quotations. You can easily track the progress of your unpaid quotation and manage them efficiently."
          );
          break;
        case "declined-quotations":
          const declinedData = quotationData.filter(
            (item) => item.payment_status === "declined"
          );

          setFilterShipmentData(declinedData);
          setDescription(
            "This page displays a table listing all declined quotations. You can easily track the progress of your declined quotation and manage them efficiently."
          );
          break;
        default:
          setFilterShipmentData(quotationData);
          break;
      }
    };

    applyFilter();
  }, [location.pathname, quotationData]);

  return (
    <>
      <div className="dashboard-title">
        <h1 className="page-title">
          {" "}
          {location.pathname
            .replace("/dashboard/", "")
            .replace("-", " ")
            .replace("-", " ")
            .toUpperCase()}
        </h1>

        <span>{description}</span>
      </div>
      <div className="second-section">
        {/* <ShipmentTable shipments={shipmentFilterData} /> */}
        <QuotesTable data={filterShipmentData} />
      </div>
    </>
  );
}
