import axios from "axios";
const BASE_URL = "https://api.shipperswise.com";

export const trackquotation = async (quotationId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/track-quotation?quotation_id=${quotationId}`
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.error);
  }
};
