import React, { useEffect, useRef, useState } from "react";
import { Link, Navigate, Outlet } from "react-router-dom";
import { clearPendingData, getPendingData } from "../../Data/PendingData";
import { sendRequest } from "../../services/RequestServices";
import Sidebar from "./Components/Sidebar";
import "./Layout.css";
import RequestModal from "../../components/RequestModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../components/Loader/Loader";
import Logo from "../../components/ui/Logo";

const Layout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [activeMenuItem, setActiveMenuItem] = useState("dashboard");
  const menuRef = useRef(null);

  const [showRequestModal, setShowRequestModal] = useState(false);
  const [requestModalState, setRequestModalState] = useState({
    loading: false,
    error: null,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const checkAuthentication = () => {
    const token = localStorage.getItem("token");
    return !!token;
  };

  useEffect(() => {
    const checkAndSubmitRequest = async () => {
      if (!checkAuthentication()) {
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      setShowRequestModal(false);
      setRequestModalState({ loading: false, error: null });

      try {
        const pendingData = getPendingData();
        console.log({ pendingData });

        if (pendingData && pendingData.pendingFormData && pendingData.service) {
          setShowRequestModal(true);
          setRequestModalState({ loading: true, error: null });

          try {
            await sendRequest(pendingData.service, pendingData.pendingFormData);
            setRequestModalState({ loading: false, error: null });
            toast.success("Pending requests submitted successfully!");
            clearPendingData();
          } catch (error) {
            console.error("Error sending request:", error);
            setRequestModalState({
              loading: false,
              error: "Failed to send request. Please try again.",
            });
            toast.error("An error occurred while sending the request.");
          }
        } else {
          console.log("No pending data to process");
        }
      } catch (error) {
        console.error("Error in checkAndSubmitRequest:", error);
        setRequestModalState({
          loading: false,
          error: "Failed to process pending requests. Please try again.",
        });
        toast.error("An error occurred while processing pending requests.");
      } finally {
        setIsLoading(false);
      }
    };

    const authStatus = checkAuthentication();
    setIsAuthenticated(authStatus);
    if (authStatus) {
      checkAndSubmitRequest();
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsSidebarOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleMenuItemClick = (menuItem) => {
    setActiveMenuItem(menuItem);
  };

  useEffect(() => {
    // Add or remove body class when sidebar state changes
    if (isSidebarOpen) {
      document.body.classList.add("sidebar-open");
    } else {
      document.body.classList.remove("sidebar-open");
    }

    return () => {
      document.body.classList.remove("sidebar-open");
    };
  }, [isSidebarOpen]);

  if (isLoading) {
    return <Loader />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="app-container">
      <ToastContainer />
      <Sidebar
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        activeMenuItem={activeMenuItem}
        onMenuItemClick={handleMenuItemClick}
      />
      <div className={`main ${isSidebarOpen ? "sidebar-open" : ""}`}>
        <div className="top-nav">
          <div style={{ display: "flex", gap: "24px", alignItems: "center" }}>
            <span className="logo-div">
              <Logo small />
            </span>

            <i
              className="material-icons icon toggle-icon"
              onClick={toggleSidebar}
              style={{ color: "#40475c", fontSize: "28px" }}
            >
              {isSidebarOpen
                ? "format_indent_decrease"
                : "format_indent_increase"}
            </i>
          </div>

          <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
            <div className="nav-item dropdown hidden-on-mobile">
              <Link
                className="nav-link dropdown-toggle"
                to=""
                id="addDropdownLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i
                  className="material-icons"
                  style={{ color: "#40475c", fontSize: "28px" }}
                >
                  add
                </i>
              </Link>
              <ul className="dropdown-menu" aria-labelledby="addDropdownLink">
                <li>
                  <Link className="dropdown-item" to="/dashboard/get-quotes">
                    Request a Quote
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/dashboard/parcel-tracking"
                  >
                    Track a Shipment
                  </Link>
                </li>
              </ul>
            </div>

            <i
              className="material-icons icon"
              style={{
                color: "#40475c",
                fontSize: "28px",
                width: "fit-content",
              }}
            >
              support_agent
            </i>
          </div>
        </div>

        <div className={`main-content ${isSidebarOpen ? "sidebar-open" : ""}`}>
          <Outlet />
        </div>
      </div>

      <RequestModal
        show={showRequestModal}
        onClose={() => setShowRequestModal(false)}
        loading={requestModalState.loading}
        error={requestModalState.error}
      />
    </div>
  );
};

export default Layout;
