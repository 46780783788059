import React from "react";
import CourierDetailsModal from "../Modals/CourierDetailsModal";
import RelocationDetailsModal from "../Modals/RelocationDetailsModal";
import RoadFreightDetailsModal from "../Modals/RoadFreightDetailsModal";
import AirFreightDetailsModal from "../Modals/AirFreightDetailsModal";

export default function ShipmentModal({
  shipment,
  additional_details,
  handleCloseModal,
  showModal,
  isModalLoading,
  isQuotes,
}) {
  return (
    <>
      {isModalLoading ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1050,
          }}
        >
          <div
            className="spinner-border text-light"
            style={{ width: "3rem", height: "3rem" }}
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          {shipment.shipment_type === "Courier" && (
            <CourierDetailsModal
              shipment={shipment}
              additional_details={additional_details}
              handleCloseModal={handleCloseModal}
              showModal={showModal}
              isQuotes
            />
          )}
          {shipment.shipment_type === "Relocation" && (
            <RelocationDetailsModal
              shipment={shipment}
              additional_details={additional_details}
              handleCloseModal={handleCloseModal}
              showModal={showModal}
              isQuotes
            />
          )}
          {shipment.shipment_type === "Road Freight" && (
            <RoadFreightDetailsModal
              shipment={shipment}
              additional_details={additional_details}
              handleCloseModal={handleCloseModal}
              showModal={showModal}
              isQuotes
            />
          )}
          {shipment.shipment_type === "Air Freight" && (
            <AirFreightDetailsModal
              shipment={shipment}
              additional_details={additional_details}
              handleCloseModal={handleCloseModal}
              showModal={showModal}
              isQuotes
            />
          )}
          {shipment.shipment_type === "Rail Freight" && (
            <AirFreightDetailsModal
              shipment={shipment}
              additional_details={additional_details}
              handleCloseModal={handleCloseModal}
              showModal={showModal}
              isQuotes
            />
          )}

          {showModal && <div className="modal-backdrop fade show"></div>}
        </>
      )}
    </>
  );
}
