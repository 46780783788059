import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Table from "../Components/Table";
import Loader from "../../../components/Loader/Loader";
import userService from "../../../services/userService";
import { toast } from "react-toastify";

export default function Shipment() {
  const [shipmentFilterData, setFilterShipmentData] = useState([]);
  const [shipmentData, setShipment] = useState([]);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState(
    "All your shipments on Shipperswise, irrespective of status."
  );

  

  const location = useLocation();

  const fetchShipments = async () => {
    setLoading(true);
    try {
      const data = await userService.getUserShipments();
      setShipment(data);
      console.log({data})
    } catch (error) {
      console.error("Failed to load recent shipments:", error);
      toast.error(error.message === "Network Error" ? error : null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchShipments();
  }, []);

  useEffect(() => {
    const applyFilter = () => {
      const path = location.pathname.replace("/dashboard/", "");

      let filteredData;

      switch (path) {
        case "all-shipments":
          filteredData = shipmentData;
          setDescription(
            "All your shipments on Shipperswise, irrespective of status."
          );
          break;
        case "pending-shipments":
          filteredData = shipmentData.filter(
            (item) => item.delivery_status === "pending"
          );
          setDescription("Shipment awaiting confirmation and processing.");
          break;
        case "failed-shipments":
          filteredData = shipmentData.filter(
            (item) => item.delivery_status === "failed"
          );
          setDescription("Shipment failed due to an issue from Shipperswise.");
          break;
        case "declined-shipments":
          filteredData = shipmentData.filter(
            (item) => item.delivery_status === "declined"
          );
          setDescription("Shipment canceled or rejected by the user.");
          break;
        case "shipments-in-transit":
          filteredData = shipmentData.filter(
            (item) => item.delivery_status === "in transit"
          );
          setDescription("Shipment currently on its way to the destination.");
          break;
        case "delivered-shipments":
          filteredData = shipmentData.filter(
            (item) => item.delivery_status === "completed"
          );
          setDescription("Shipment successfully delivered to the recipient.");
          break;
        default:
          filteredData = shipmentData;
          break;
      }

      setFilterShipmentData(filteredData);
    };

    applyFilter();
  }, [location.pathname, shipmentData]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="dashboard-title">
        <h1 className="page-title">
          {location.pathname
            .replace("/dashboard/", "")
            .replace("-", " ")
            .replace("-", " ")
            .toUpperCase()}
        </h1>
        <span>{description}</span>
      </div>

      <div className="second-section">
        <Table data={shipmentFilterData} />
      </div>
    </>
  );
}
