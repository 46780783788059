import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement, // Register BarElement for bar charts
  Title,
  Tooltip,
  Legend
);

const DashboardChart = ({ chartData }) => {
  const [roundedMax, setRoundedMax] = useState(0);

  useEffect(() => {
    const totalShipments = chartData?.map((item) => item.total_shipments);

    const completedShipments = chartData?.map(
      (item) => item.completed_shipments
    );

    if (totalShipments && completedShipments) {
      const totalShipmentsData = totalShipments.reduce(
        (acc, curr) => acc + curr,
        0
      );
      const completedShipmentsData = completedShipments.reduce(
        (acc, curr) => acc + curr,
        0
      );

      const max = Math.max(totalShipmentsData, completedShipmentsData);
      const roundedMax = Math.ceil(max / 10) * 10;

      setRoundedMax(roundedMax);
    }
  }, [chartData]);

  const data = {
    labels: chartData?.map((item) => item.month),
    datasets: [
      {
        label: "Total Shipments",
        data: chartData?.map((item) => item.total_shipments),
        borderColor: "rgba(59, 130, 246, 1)",
        backgroundColor: "#1b83c5",
        fill: true,
        tension: 0.4,
      },
      {
        label: "Completed Shipments",
        data: chartData?.map((item) => item.completed_shipments),
        borderColor: "rgba(16, 185, 129, 1)",
        backgroundColor: "#4bad48",
        fill: true,
        tension: 0.4,
      },
      // {
      //   label: "Pending",
      //   data: [20, 15, 10, 0, 25, 5, 23, 5, 8, 4, 15, 12, 20, 22],
      //   borderColor: "rgba(239, 68, 68, 1)",
      //   backgroundColor: "#ff9500",
      //   fill: true,
      //   tension: 0.4,
      // },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Ensures chart adjusts dynamically with the page
    plugins: {
      legend: {
        position: "top",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          padding: 20,
          font: {
            size: 12,
            family: "'Inter', sans-serif",
          },
        },
      },
      title: {
        display: true,
        text: "Shipments this year",
        font: {
          size: 20,
          family: "'Inter', sans-serif",
          weight: "bold",
        },
        padding: {
          top: 10,
          bottom: 30,
        },
      },
      tooltip: {
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        titleColor: "#1f2937",
        bodyColor: "#4b5563",
        borderColor: "#e5e7eb",
        borderWidth: 1,
        padding: 12,
        bodyFont: {
          size: 14,
          family: "'Inter', sans-serif",
        },
        titleFont: {
          size: 16,
          family: "'Inter', sans-serif",
          weight: "bold",
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
            family: "'Inter', sans-serif",
          },
        },
      },
      y: {
        grid: {
          color: "rgba(156, 163, 175, 0.1)",
        },
        ticks: {
          font: {
            size: 12,
            family: "'Inter', sans-serif",
          },
        },
        min: 0, // Set minimum value for the Y-axis
        max: roundedMax,
        beginAtZero: true,
      },
    },
  };

  return (
    
    <div className="row">
      <div className="col-md-12">
        
        <div
          className=" custom-card shadow-lg mb-4"
          style={{
            borderRadius: "10px",
            overflow: "hidden",
            backgroundColor: "#ffffff",
          }}
        >
          <div
            className="card-body"
            style={{
              padding: "24px",
              width: "100%",
              height: "550px",
              boxSizing: "border-box",
            }}
          >
            {chartData && chartData?.length > 0 ?  <Bar data={data} options={options} /> : <>
            <div className="d-flex align-content-center flex-column justify-content-center" style={{
                  height: "100%",
                }}>
              <div className="d-flex align-content-center justify-content-center">
              <img
                src="/assets/img/no-result.jpg"
                alt="No Tracking Data"
                style={{
                  width: "20%",
                  height: "auto",
                  margin: "0 auto",
                  opacity: "0.4",
                  objectFit: "contain",
                }}
              />
            </div>
            <p style={{ textAlign: "center", width: "100%", color: "#aaa", fontSize: "18px" }}>
               No shipment has been found this year
            </p>
            </div>
            
          </>}             
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardChart;
