import React, { useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Logo from "../../../components/ui/Logo";
// import CloseIcon from "assets/img/close_button.svg";
import "./Sidebar.css";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openSubMenu, setOpenSubMenu] = useState(null);

  const handleMenuItemClick = (menuItem) => {
    if (menuItem.id === "logout") {
      localStorage.removeItem("token");
      navigate("/login");
    } else if (menuItem.subItems) {
      setOpenSubMenu(openSubMenu === menuItem.id ? null : menuItem.id);
    }
  };

  const isActive = (path) => location.pathname === `/dashboard${path}`;

  const menuItems = [
    { id: "dashboard", icon: "dashboard", label: "Dashboard", path: "/" },
    {
      id: "get-quotes",
      icon: "request_quote",
      label: "Request Quote",
      path: "/get-quotes",
    },
    {
      id: "shipments",
      icon: "local_shipping",
      label: "Shipments",
      subItems: [
        { id: "all-shipments", label: "All Shipments", path: "/all-shipments" },
        {
          id: "pending-shipments",
          label: "Pending Shipments",
          path: "/pending-shipments",
        },
        {
          id: "in-transit-shipments",
          label: "Shipments in transit",
          path: "/shipments-in-transit",
        },
        {
          id: "delivered-shipments",
          label: "Delivered Shipments",
          path: "/delivered-shipments",
        },
        {
          id: "declined-shipments",
          label: "Declined Shipments",
          path: "/declined-shipments",
        },
        // {
        //   id: "failed-shipments",
        //   label: "Failed Shipments",
        //   path: "/failed-shipments",
        // },
      ],
    },
    {
      id: "parcel-tracking",
      icon: "search",
      label: "Parcel Tracking",
      path: "/parcel-tracking",
    },
    {
      id: "quotes",
      icon: "description",
      label: "Quotations",
      subItems: [
        {
          id: "unpaid-quotation",
          label: "Unpaid Quotations",
          path: "/unpaid-quotations",
        },
        {
          id: "paid-quotation",
          label: "Paid Quotations",
          path: "/paid-quotations",
        },
        {
          id: "declined-quotation",
          label: "Declined Quotations",
          path: "/declined-quotations",
        },
        {
          id: "all-quotation",
          label: "All Quotations",
          path: "/all-quotations",
        },
      ],
    },
    { id: "profile", icon: "person", label: "Profile", path: "/profile" },
    {
      id: "email-notification",
      icon: "notifications",
      label: "Email Notifications",
      path: "/email-notification",
    },
    {
      id: "change-password",
      icon: "lock",
      label: "Change Password",
      path: "/change-password",
    },
    { id: "logout", icon: "logout", label: "Logout" },
  ];

  return (
    <div className={`sidebar ${isOpen ? "open" : ""}`}>
      <div
        className="sidebar-header"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {isOpen ? <Logo /> : <Logo small />}{" "}
        <img
          onClick={toggleSidebar}
          src="/assets/img/close_button.svg"
          alt="Close icon"
          className="close-icon"
        />
      </div>
      <ul className="sidebar-menu">
        {menuItems.map((item) => (
          <React.Fragment key={item.id}>
            {item.path ? (
              <li
                className={`${isActive(item.path) ? "active" : ""} ${
                  item.subItems ? "has-submenu" : ""
                }`}
              >
                <Link
                  to={`/dashboard${item.path}`}
                  onClick={() => handleMenuItemClick(item)}
                >
                  <i className="material-icons-two-tone">{item.icon}</i>
                  {isOpen && <span>{item.label}</span>}
                  {item.subItems && (
                    <i
                      className={`material-icons-two-tone ms-auto ${
                        openSubMenu === item.id ? "rotate" : ""
                      }`}
                    >
                      {openSubMenu === item.id ? "expand_less" : "expand_more"}
                    </i>
                  )}
                </Link>
              </li>
            ) : (
              <li
                className={`${item.subItems ? "has-submenu" : ""}`}
                onClick={() => handleMenuItemClick(item)}
              >
                <i className="material-icons-two-tone">{item.icon}</i>
                {isOpen && <span>{item.label}</span>}
                {item.subItems && (
                  <i
                    className={`material-icons-two-tone ms-auto ${
                      openSubMenu === item.id ? "rotate" : ""
                    }`}
                  >
                    {openSubMenu === item.id ? "expand_less" : "expand_more"}
                  </i>
                )}
              </li>
            )}
            {item.subItems && openSubMenu === item.id && (
              <ul className="sub-menu">
                {item.subItems.map((subItem) => (
                  <li
                    key={subItem.id}
                    className={isActive(subItem.path) ? "active" : ""}
                  >
                    <Link to={`/dashboard${subItem.path}`}>
                      {subItem.label}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </React.Fragment>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
