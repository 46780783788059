import React, { useEffect, useState } from "react";
import "./QuotationPage.css";
import Logo from "../../../components/ui/Logo";
import { useParams } from "react-router-dom";
import { trackquotation } from "../../../services/trackQuotation";
import Loader from "../../../components/Loader/Loader";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const currencyMap = {
  GBP: { name: "Pounds", symbol: "£" },
  EUR: { name: "Euro", symbol: "€" },
  USD: { name: "Dollars", symbol: "$" },
};

const QuotationPage = () => {
  const { quotationId } = useParams();
  const [quotationData, setQuotationData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPaying, setIsPaying] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchQuotation = async () => {
      try {
        const data = await trackquotation(quotationId);
        setQuotationData(data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch quotation data");
        setLoading(false);
      }
    };

    if (quotationId) {
      fetchQuotation();
    }
  }, [quotationId]);

  const onPayNowClick = async () => {
    setIsPaying(true);
    try {
      const res = await axios.post(
        "https://api.shipperswise.com/create-payment",
        { quotation_id: quotationId },
        { accept: "application/json", contentType: "application/json" }
      );
      console.log(res);
      window.location.href = res?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsPaying(false);
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <ToastContainer />
      <div className="quotation-container">
        <header className="quotation-header" style={{ position: "relative" }}>
          <div>
            <Logo />
            <div className="quote-title">
              <div className="small-line"></div>
              <div className="big-line"></div>
              Quotation
            </div>
          </div>

          <div>
            {/* Dynamically displaying the status of the quotation */}
            <p className={`status-tag ${quotationData.quotation_status}`}>
              {quotationData.quotation_status.toUpperCase()}
            </p>
          </div>
          <div className="quote-info">
            <p>Valid till: {quotationData.quotation_valid_through_date}</p>
            <p>
              Total: {currencyMap[quotationData.currency_type]?.symbol}
              {quotationData.quotation_sub_total}
            </p>
          </div>
        </header>

        <section>
          <div>
            <p className="bold-text">
              Quotation ID: {quotationData.shipment_id}
            </p>
            <p>Date: <span style={{fontWeight: "600"}}>{quotationData.quotation_valid_through_date}</span></p>
          </div>
        </section>

        <div className="line-break"></div>

        <div className="quote-details">
          <div className="quote-from">
            <p className="bold-text">From</p>
            <p>ShipperWise</p>
            <p>Street Address, Zip Code</p>
            <p>Phone Number</p>
          </div>
          <div className="quote-to">
            <p className="bold-text">To:</p>
            <p> {quotationData.user_name}</p>
            <p>{quotationData.user_email}</p>
            <p>
              {quotationData.user_postal_code}, {quotationData.user_city},{" "}
              {quotationData.user_country}
            </p>
            <p>{quotationData.user_phone}</p>
          </div>
        </div>

        <div className="line-break two"></div>

        <div className="d-flex justify-content-between align-items-center table-title">
          <p>Item</p>
          <p className="table-rate">Rate</p>
          <p>Quantity</p>
          <p>Total</p>
        </div>

        <div className="line-break two"></div>

        {/* Dynamically rendering the items */}
        {quotationData.quotation_items.map((item, index) => (
          <div key={index}>
            <div className="d-flex justify-content-between table-body">
              <div>
                <p
                  className="bold-text"
                  style={{ fontWeight: "500", color: "#000" }}
                >
                  {item.item_name}
                </p>
                <p>{item.description}</p>
              </div>
              <p>
                {currencyMap[quotationData.currency_type]?.symbol}
                {item.rate}
              </p>
              <p>{item.quantity}</p>
              <p>
                {currencyMap[quotationData.currency_type]?.symbol}
                {item.total}
              </p>
            </div>
            <div className="black"></div>
          </div>
        ))}

        <div
          className="d-flex justify-content-between"
          style={{ padding: "0px 20px" }}
        >
          <div className="instructions">
            <p className="bold-text" style={{ marginTop: "20px" }}>
              Note:
            </p>
            <p>{quotationData.quotation_notes}</p>
          </div>
          <div className="totals">
            <p>Subtotal: {currencyMap[quotationData.currency_type]?.symbol}{quotationData.quotation_sub_total}</p>
            <p>Discount: {currencyMap[quotationData.currency_type]?.symbol}{quotationData.quotation_discount_amount}</p>
            <p>Tax: {currencyMap[quotationData.currency_type]?.symbol}{quotationData.quotation_tax_percent}</p>
          </div>
        </div>

        <div className="line-break"></div>

        <section className="total-wrapper">
          <div className="signature">
            <div className="black"></div>
            <p>Authorized signature:</p>
          </div>

          <div className="total-balance">
            <div className="d-flex justify-content-center align-items-center balance-title">
              <p>Total </p>
              <p>
                {currencyMap[quotationData.currency_type]?.symbol}
                {quotationData.quotation_sub_total}
              </p>
            </div>
            {quotationData.quotation_status === "unpaid" && (
              <div className="now-btn" onClick={onPayNowClick}>
                {isPaying ? "proceeding..." : <p>Pay Now</p>}
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default QuotationPage;
