import React, { useEffect, useState } from "react";
import "./Table.css";
import ShipmentModal from "./ShipmentModal";
import { shipmentDetails } from "../../../services/shipmentDetailsService";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import userService from "../../../services/userService";
import { Button } from "react-bootstrap";
import DeclineQuotationModal from "./DeclineModal";

const currencyMap = {
  GBP: { name: "Pounds", symbol: "£" },
  EUR: { name: "Euro", symbol: "€" },
  USD: { name: "Dollars", symbol: "$" },
};

const QuotesTable = ({ data }) => {
  const [filteredData, setFilteredData] = useState(data);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [shipmentDetailsData, setShipmentDetailsData] = useState(null);
  const [preferredCurrency, setPreferredCurrency] = useState("");
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const results = data?.filter(
      (dataItem) =>
        dataItem?.quotation_id
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        dataItem?.shipment_id
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        dataItem?.shipment_type
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        dataItem?.date?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        dataItem?.payment_status
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase())
    );
    setFilteredData(results);
    setCurrentPage(1);
  }, [searchTerm, data]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleEntriesPerPageChange = (event) => {
    setEntriesPerPage(parseInt(event.target.value));
    setCurrentPage(1);
  };

  useEffect(() => {
    const handleGetCurrency = async () => {
      try {
        const data = await userService.getPreferredCurrency();
        setPreferredCurrency(data?.preferred_currency);
      } catch (error) {
        toast.error("Failed to get preferred currency information");
      }
    };

    handleGetCurrency();
  }, [preferredCurrency]);

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredData?.slice(
    indexOfFirstEntry,
    indexOfLastEntry
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleShowDeclineModal = () => {
    setShowDeclineModal(true);
  };

  const handleRowClick = (shipmentId) => {
    window.open(`/quotation/${shipmentId}`);
    // navigate(`/quotation/${shipmentId}`);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShipmentDetailsData(null);
  };

  const handleShowModal = async (shipment) => {
    setIsModalLoading(true);
    setShowModal(true);
    try {
      const res = await shipmentDetails(shipment);
      console.log({ res });
      setShipmentDetailsData(res);
      setIsModalLoading(false);
    } catch (error) {
      console.log(error);
      setShowModal(false);
      toast.error(error);
    } finally {
      setIsModalLoading(false);
    }
  };

  return (
    <div className="container-fluid mt-5">
      {/* Search and Filter Controls */}

      <div className="row mb-3">
        <div className="col-md-6">
          {entriesPerPage > 10 && (
            <div className="d-flex align-items-center">
              <label className="me-2">Show</label>
              <select
                className="form-select form-select-sm w-auto"
                value={entriesPerPage}
                onChange={handleEntriesPerPageChange}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <label className="ms-2">entries</label>
            </div>
          )}
        </div>
        <div className="col-md-3 "></div>
        <div className="col-md-3">
          <div className="search-table">
            <span className="search-text">Search</span>
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th>Date</th>
              <th>Quotation ID</th>
              <th>Shipment ID</th>
              <th>Amount</th>
              <th>Shipment Type</th>
              <th>Payment Status</th>
              <th>Action</th>
            </tr>
          </thead>

          {currentEntries && currentEntries.length > 0 && (
            <tbody>
              {currentEntries?.map((shipment, index) => (
                <tr key={shipment.shipment_id} style={{ cursor: "pointer" }}>
                  <td>{indexOfFirstEntry + index + 1}</td>
                  <td>{shipment.date}</td>
                  <td>{shipment.quotation_id}</td>
                  <td>
                    {shipment.shipment_id}
                    <br />
                    <Button
                      variant="link"
                      className="p-0"
                      onClick={() => handleShowModal(shipment?.shipment_id)}
                      style={{ zIndex: 3 }}
                    >
                      All info
                    </Button>
                  </td>
                  <td className="text-capitalize" style={{ fontWeight: "600" }}>
                    {shipment.amount ? (
                      <>
                        {currencyMap[preferredCurrency]?.symbol}
                        {shipment.amount}
                      </>
                    ) : (
                      <p className="text-italics fst-italic">
                        pending quotation
                      </p>
                    )}
                  </td>
                  <td className="text-capitalize">{shipment.shipment_type}</td>
                  <td>
                    {shipment.payment_status === "paid" && (
                      <div className={` c-pill c-pill--success `}>
                        {shipment.payment_status}
                      </div>
                    )}
                    {shipment.payment_status === "unpaid" && (
                      <div className={` c-pill c-pill--danger `}>
                        {shipment.payment_status}
                      </div>
                    )}
                    {shipment.payment_status === "declined" && (
                      <div className={` c-pill c-pill--danger `}>
                        {shipment.payment_status}
                      </div>
                    )}
                  </td>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <Link
                        className="view-btn btn-generic"
                        onClick={() => handleRowClick(shipment.quotation_id)}
                      >
                        View
                      </Link>
                      |
                      <button
                        className="print-btn btn-generic"
                        onClick={() =>
                          handleShowDeclineModal(shipment.quotation_id)
                        }
                      >
                        Decline
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
        {currentEntries && currentEntries.length <= 0 && (
          <>
            <div className="d-flex align-content-center">
              <img
                src="/assets/img/no-result.jpg" 
                alt="No Tracking Data"
                style={{
                  width: "10%",
                  height: "auto",
                  margin: "0 auto",
                  opacity: "0.4",
                  objectFit: "contain",
                }}
              />
            </div>
            <p style={{ textAlign: "center", width: "100%", color: "#aaa" }}>
              No quotations found
            </p>
          </>
        )}
      </div>

      {/* Pagination and Modals */}
      {currentEntries && currentEntries.length > 0 && (
        <div className="row">
          <div className="col-md-6">
            <p>
              Showing {indexOfFirstEntry + 1} to{" "}
              {Math.min(indexOfLastEntry, filteredData?.length)} of{" "}
              {filteredData?.length} entries
            </p>
          </div>
          <div className="col-md-6">
            <nav>
              <ul className="pagination justify-content-end align-center-content">
                <li
                  className={`page-item d-flex align-items-center ${
                    currentPage === 1 ? "disabled" : ""
                  }`}
                >
                  <i
                    className="material-icons-two-tone"
                    onClick={() => paginate(currentPage - 1)}
                  >
                    chevron_left
                  </i>
                </li>
                {Array.from({
                  length: Math.ceil(filteredData?.length / entriesPerPage),
                }).map((_, index) => (
                  <li
                    key={index}
                    className={`page-item pagination-item ${
                      currentPage === index + 1 ? "active-pagination" : ""
                    }`}
                  >
                    <button
                      className="page-number"
                      onClick={() => paginate(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item d-flex align-items-center ${
                    currentPage ===
                    Math.ceil(filteredData?.length / entriesPerPage)
                      ? "disabled"
                      : ""
                  }`}
                >
                  <i
                    className="material-icons-two-tone"
                    onClick={() => paginate(currentPage + 1)}
                  >
                    chevron_right
                  </i>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      )}

      {showModal && (
        <ShipmentModal
          isModalLoading={isModalLoading}
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          shipment={shipmentDetailsData?.shipment}
          additional_details={shipmentDetailsData?.additional_details}
          isQuotes={true}
        />
      )}

      {showModal && <div className="modal-backdrop fade show"></div>}

      {showDeclineModal && (
        <DeclineQuotationModal
          quotationId="135465767"
          showModal={showDeclineModal}
          handleCloseModal={() => setShowDeclineModal(false)}
        />
      )}
    </div>
  );
};

export default QuotesTable;
