import React from "react";

export default function AboutUs() {
  return (
    <>
      <section className="page-title-area sky-blue-bg pt-160 pb-100 ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6">
              <div className="page-title-wrapper text-center">
                <h4 className="styled-text theme-color mb-30">
                  Know more about us
                </h4>
                <h5 className="h1 page-title">About us</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
