import api from "./apiService";

const userService = {
  // Get User Dashboard Stats
  getDashboardStats: async () => {
    try {
      const response = await api.get("/dashboard-stats");
      return response.data;
    } catch (error) {
      console.error("Error fetching dashboard stats:", error);
      throw error;
    }
  },

  // Get User's Last 5 Shipments
  getUserRecentShipments: async () => {
    try {
      const response = await api.get("/user-recent-shipments");
      return response.data;
    } catch (error) {
      console.error("Error fetching recent shipments:", error);
      throw error;
    }
  },

  // Get All Shipments for User
  getUserShipments: async () => {
    try {
      const response = await api.get("/user-shipments");
      return response.data;
    } catch (error) {
      console.error("Error fetching user shipments:", error);
      throw error;
    }
  },

  // Get Pending Shipments for User
  getUserPendingShipments: async () => {
    try {
      const response = await api.get("/user-pending-shipments");
      return response.data;
    } catch (error) {
      console.error("Error fetching pending shipments:", error);
      throw error;
    }
  },

  // Get In Progress Shipments
  getUserInProgressShipments: async () => {
    try {
      const response = await api.get("/user-progress-shipments");
      return response.data;
    } catch (error) {
      console.error("Error fetching in-progress shipments:", error);
      throw error;
    }
  },

  // Get Failed Shipments
  getUserFailedShipments: async () => {
    try {
      const response = await api.get("/user-failed-shipments");
      return response.data;
    } catch (error) {
      console.error("Error fetching failed shipments:", error);
      throw error;
    }
  },

  // Get Declined Shipments
  getUserDeclinedShipments: async () => {
    try {
      const response = await api.get("/user-declined-shipments");
      return response.data;
    } catch (error) {
      console.error("Error fetching declined shipments:", error);
      throw error;
    }
  },

  // Get Completed Shipments
  getUserCompletedShipments: async () => {
    try {
      const response = await api.get("/user-completed-shipments");
      return response.data;
    } catch (error) {
      console.error("Error fetching completed shipments:", error);
      throw error;
    }
  },

  // Get User Details
  getUserDetails: async () => {
    try {
      const response = await api.get("/user-details");
      return response.data;
    } catch (error) {
      console.error("Error fetching user details:", error);
      throw error;
    }
  },

  // Update User Details
  updateUserDetails: async (userData) => {
    try {
      const response = await api.put("/update-user", userData);
      return response.data;
    } catch (error) {
      console.error("Error updating user details:", error);
      throw error;
    }
  },

  // Get User Notifications
  getUserNotifications: async () => {
    try {
      const response = await api.get("/user-notifications");
      return response.data;
    } catch (error) {
      console.error("Error fetching user notifications:", error);
      throw error;
    }
  },

  // Update User Notifications
  updateUserNotifications: async (notificationSettings) => {
    try {
      const response = await api.put(
        "/update-user-notifications",
        notificationSettings
      );
      return response.data;
    } catch (error) {
      console.error("Error updating user notifications:", error);
      throw error;
    }
  },

  // Change User Password
  changeUserPassword: async (passwordData) => {
    try {
      const response = await api.put("/user-change-password", passwordData);
      return response.data;
    } catch (error) {
      console.error("Error changing password:", error);
      throw error;
    }
  },

  getPreferredCurrency: async () => {
    const response = await api.get("/get-preferred-currency", {
      method: "GET",
    });
    return await response.data;
  },

  updatePreferredCurrency: async (currencyData) => {
    const response = await api.put("/update-preferred-currency", currencyData);
    return await response.data;
  },

  getUserQuotation: async () => {
    try {
      const response = await api.get("/get-quotations");
      return response.data;
    } catch (error) {
      console.error("Error fetching user notifications:", error);
      throw error;
    }
  },

  declineQuotationModal: async (quotationID, reason) => {
    try {
      const response = await api.post(
        `/decline-quotation?quotation_id=${quotationID}&reason=${encodeURIComponent(
          reason
        )}`
      );
      return response.data;
    } catch (error) {
      console.error("Error declining quotation:", error);
      throw error;
    }
  },
};

export default userService;
