import React, { useState } from "react";
import "./LoginPage.css";
import AuthPageLayout from "./AuthPageLayout";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const SignUp = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
    acceptPolicy: false,
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const navigate = useNavigate();

  const handleChange = (e) => {
    setErrors({});
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckChange = () => {
    setErrors({});
    setFormData((prev) => ({ ...prev, acceptPolicy: !formData.acceptPolicy }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.firstName) newErrors.firstName = "Full Name is required";
    if (!formData.lastName) newErrors.lastName = "Full Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Email is invalid";

    if (!formData.password) newErrors.password = "Password is required";
    if (!formData.acceptPolicy)
      newErrors.acceptPolicy = "Please accept policy to proceed";
    if (formData.password !== formData.confirmPassword)
      newErrors.confirmPassword = "Passwords do not match";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Sign-up logic
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    const userData = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      password: formData.password,
      retype_password: formData.confirmPassword,
    };

    console.log(userData);

    setLoading(true);
    setSuccessMessage("");

    try {
      const response = await axios.post(
        "https://api.shipperswise.com/create-user",
        userData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setSuccessMessage("Account created successfully!");
      toast.success("Account created successfully!");
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
      });
      localStorage.setItem("token", response.data.token);
      setTimeout(() => {
        navigate("/dashboard");
      }, 3000);
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to login");
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthPageLayout>
      <ToastContainer />
      <div className="login-form">
        <h1 className="mb-4 text-center">Create Account</h1>

        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="firstName" className="form-label">
              First Name
            </label>
            <input
              type="text"
              className="form-control"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
            />
            {errors.firstName && (
              <p className="text-danger">{errors.firstName}</p>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="lastName" className="form-label">
              Last Name
            </label>
            <input
              type="text"
              className="form-control"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
            />
            {errors.lastName && (
              <p className="text-danger">{errors.lastName}</p>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <p className="text-danger">{errors.email}</p>}
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <input
              type="password"
              className="form-control"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
            {errors.password && (
              <p className="text-danger">{errors.password}</p>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="confirmPassword" className="form-label">
              Confirm Password
            </label>
            <input
              type="password"
              className="form-control"
              id="confirmPassword"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
            />
            {errors.confirmPassword && (
              <p className="text-danger">{errors.confirmPassword}</p>
            )}
          </div>

          <div style={{ marginBottom: "20px" }}>
            <input
              type="checkbox"
              id="acceptPolicy"
              name="acceptPolicy"
              checked={formData.acceptPolicy}
              onChange={handleCheckChange}
              style={{ display: "inline" }}
            />
            <label
              htmlFor="acceptPolicy"
              style={{ marginLeft: "5px", display: "inline" }}
            >
              I confirm that I have read, consent and agree to ShippersWise{" "}
              <Link href="#" style={{ textDecoration: "underline" }}>
                Terms of service
              </Link>{" "}
              and{" "}
              <Link href="#" style={{ textDecoration: "underline" }}>
                Privacy Policy
              </Link>
              , and I am of legal age. I understand that I can change my
              preferences anytime in my ShippersWise account.
            </label>

            {errors.acceptPolicy && (
              <p className="text-danger">{errors.acceptPolicy}</p>
            )}
          </div>

          {errors.apiError && <p className="text-danger">{errors.apiError}</p>}
          {successMessage && <p className="text-success">{successMessage}</p>}

          <button
            type="submit"
            className="btn btn-primary w-100 mb-3"
            disabled={loading}
          >
            {loading ? "Creating Account..." : "Create Account"}
          </button>
          <p className="text-center mb-3 form-label">Or</p>
          <button
            type="button"
            className="w-100 d-flex align-items-center justify-content-center login-with-google"
          >
            <img
              src="/assets/img/Google.svg"
              alt="Google Icon"
              className="google-icon me-3"
            />
            Create account with Google
          </button>
          <p className="text-center mt-3">
            Already have an account?{" "}
            <Link to="/login" className="create-account">
              Login
            </Link>
          </p>
        </form>
      </div>
    </AuthPageLayout>
  );
};

export default SignUp;
