import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import userService from "../../../services/userService";

export default function DeclineQuotationModal({
  quotationId,
  showModal,
  handleCloseModal,
}) {
  const [reason, setReason] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    console.log("clicked");
    if (!reason.trim()) {
      toast.error("Please provide a reason for declining");
      return;
    }

    setIsSubmitting(true);
    try {
      const response = await userService.declineQuotationModal(
        "12345689",
        reason
      );

      if (!response.ok) {
        throw new Error(response?.data?.error || "Failed to decline quotation");
      }

      console.log({ response });

      toast.success("Quotation declined successfully");
      setReason("");
      handleCloseModal();
    } catch (error) {
      console.error("Error declining quotation:", error);
      toast.error(
        error?.response?.data?.error ||
          "Failed to decline quotation. Please try again."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div
      className={`modal fade ${showModal ? "show" : ""}`}
      style={{ display: showModal ? "block" : "none" }}
      tabIndex={-1}
      role="dialog"
    >
      <ToastContainer />
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content border-0 shadow-lg">
          <div className="modal-header modal-bg text-white">
            <h5 className="modal-title fs-4 fw-bold">Decline Quotation</h5>
            <button
              type="button"
              className="btn-close btn-close-white"
              onClick={handleCloseModal}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body p-4">
            <div className="row g-4">
              <div className="col-12">
                <div className=" h-100 border-0 ">
                  <div className="">
                    <h6
                      className="card-title mb-3"
                      style={{ color: "#000066", fontSize: "20px" }}
                    >
                      Are you sure you want to decline this quotation?
                    </h6>
                    <p className="mb-3">
                      Please provide a reason for declining. This action cannot
                      be undone.
                    </p>
                    <textarea
                      placeholder="Enter your reason for declining..."
                      value={reason}
                      required
                      onChange={(e) => setReason(e.target.value)}
                      className="w-full min-h-[100px]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Link
              className="theme_btn theme_btn_border"
              onClick={handleCloseModal}
              disabled={isSubmitting}
            >
              Cancel
            </Link>
            <Link
              className="theme_btn"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Declining..." : "Decline Quotation"}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
