// import React, { useRef, useEffect, useState } from "react";
// import intlTelInput from "https://cdn.skypack.dev/intl-tel-input@19.1.0";

// const utilsScriptUrl =
//   "https://cdn.jsdelivr.net/npm/intl-tel-input@19.1.0/build/js/utils.js";

// export default function IntInput({
//   initialCountry = "us",
//   onChange,
//   value,
//   ...props
// }) {
//   const inputRef = useRef(null);
//   const itiRef = useRef(null);
//   const [internalValue, setInternalValue] = useState(value || "");

//   useEffect(() => {
//     if (inputRef.current) {
//       itiRef.current = intlTelInput(inputRef.current, {
//         initialCountry,
//         utilsScript: utilsScriptUrl,
//         autoPlaceholder: "aggressive",
//         separateDialCode: true,
//       });

//       return () => {
//         if (itiRef.current) {
//           itiRef.current.destroy();
//         }
//       };
//     }
//   }, [initialCountry]);

//   useEffect(() => {
//     if (itiRef.current && value) {
//       itiRef.current.setNumber(value);
//       setInternalValue(value);
//     }
//   }, [value]);

//   const handleInput = (e) => {
//     if (itiRef.current) {
//       const number = itiRef.current.getNumber();
//       setInternalValue(number);
//       onChange({
//         target: {
//           name: props.name,
//           value: number,
//         },
//       });
//     }
//   };

//   return (
//     <div
//       className="quantity q-style1 me-4 mb-1"
//       style={{ position: "relative", zIndex: "100" }}
//     >
//       <input
//         type="tel"
//         className="qty-input w-100 capitalize placeholder"
//         ref={inputRef}
//         value={internalValue}
//         onInput={handleInput}
//         {...props}
//       />
//     </div>
//   );
// }

import React, { useRef, useEffect, useState } from "react";
import intlTelInput from "https://cdn.skypack.dev/intl-tel-input@19.1.0";

const utilsScriptUrl =
  "https://cdn.jsdelivr.net/npm/intl-tel-input@19.1.0/build/js/utils.js";

export default function IntInput({
  initialCountry = "us",
  onChange,
  value,
  ...props
}) {
  const inputRef = useRef(null);
  const itiRef = useRef(null);
  const [internalValue, setInternalValue] = useState(value || "");

  useEffect(() => {
    if (inputRef.current) {
      itiRef.current = intlTelInput(inputRef.current, {
        initialCountry,
        utilsScript: utilsScriptUrl,
        autoPlaceholder: "aggressive",
        separateDialCode: true,
      });

      // Set initial number if value is provided
      if (value) {
        itiRef.current.setNumber(value);
      }

      return () => {
        if (itiRef.current) {
          itiRef.current.destroy();
        }
      };
    }
  }, [initialCountry, value]);

  useEffect(() => {
    if (itiRef.current && value) {
      itiRef.current.setNumber(value);
      setInternalValue(itiRef.current.getNumber()); // Ensure value includes country code
    }
  }, [value]);

  const handleInput = () => {
    if (itiRef.current) {
      const number = itiRef.current.getNumber();
      setInternalValue(number);
      onChange({
        target: {
          name: props.name,
          value: number, // Store the full number with country code
        },
      });
    }
  };

  return (
    <div
      className="quantity q-style1 me-4 mb-1"
      style={{ position: "relative", zIndex: "100" }}
    >
      <input
        type="tel"
        className="qty-input w-100 capitalize placeholder"
        ref={inputRef}
        value={internalValue}
        onChange={handleInput}
        {...props}
      />
    </div>
  );
}
