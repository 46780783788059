import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./MainSlider.css";

const MainSlider = () => {
  const navigate = useNavigate();
  const h1Ref = useRef(null);
  const h5Ref = useRef(null);

  useEffect(() => {
    if (h1Ref.current) {
      const text = `One Stop International <Freight Comparison<E-Market`;
      h1Ref.current.innerHTML = ""; // Clear the text content

      text.split("").forEach((char, index) => {
        if (char === "<") {
          // If a break tag starts, append it directly
          const br = document.createElement("br");
          h1Ref.current.appendChild(br);
        } else {
          const span = document.createElement("span");
          span.innerHTML = char === " " ? "&nbsp;" : char; // Preserve spaces
          span.style.animationDelay = `${index * 0.1}s`;
          span.className = "fade-in-from-right letter";
          h1Ref.current.appendChild(span);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (h5Ref.current) {
      const text = `Welcome to Shipperswise`;
      h5Ref.current.innerHTML = ""; // Clear the text content

      const totalChars = text.length;

      text.split("").forEach((char, index) => {
        if (char === "<") {
          // If a break tag starts, append it directly
          const br = document.createElement("br");
          h5Ref.current.appendChild(br);
        } else {
          const span = document.createElement("span");
          span.innerHTML = char === " " ? "&nbsp;" : char; // Preserve spaces
          const reverseIndex = totalChars - index; // Calculate reverse delay
          span.style.animationDelay = `${reverseIndex * 0.1}s`; //
          span.className = " letter-small";
          h5Ref.current.appendChild(span);
        }
      });
    }
  }, []);

  const handleServiceClick = (e) => {
    e.preventDefault();
    navigate(`/all-services`);
  };

  return (
    <section
      id="slider-two"
      style={{
        backgroundImage: `url('assets/img/background1.jpg')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        paddingTop: "165px",
        paddingBottom: "200px",
        paddingLeft: "20px",
        paddingRight: "20px",
      }}
      className="slider-area pos-rel"
    >
      <div className="main-slider main-slider2">
        <div className="container">
          <div className="slider__content" style={{ marginTop: "100px" }}>
            <h5 className="uppercase" ref={h5Ref}>
              &nbsp;
            </h5>
            <h1 className="main-title" ref={h1Ref}>
              &nbsp;
            </h1>
            <h5 className="bottom-h5 fade-in-from-left delay-2s">
              Instant Quotes, Simple Bookings, Track and Manage
            </h5>
            <Link onClick={handleServiceClick} className="theme_btn mt-5">
              GET STARTED
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSlider;
